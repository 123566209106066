<template>
    <div>
        <h1>Sales Rebate</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
import { store } from '@/store/Report.store.js'
import Options from './sales_rebate_opt.vue'
import GroupBy from './utils/GroupBy.vue'
import Fields from './utils/Fields.vue'
import Results from "./utils/Results";

export default {
    name: "sales_rebate",
    components: {
        Options,
        GroupBy,
        Fields,
        Results,
    },
    data() {
        return {
            state: store.state,
            optionFields: {
                startShipDate: {val: new Date().toLocaleDateString(), qsField: '[tracker][dates][actual_ship_from]'},
                endShipDate: {val: new Date().toLocaleDateString(), qsField: '[tracker][dates][actual_ship_to]'},
                startCreditDate: {val: new Date().toLocaleDateString(), qsField: '[credit][dates][cr.credit_date_from]'},
                endCreditDate: {val: new Date().toLocaleDateString(), qsField: '[credit][dates][cr.credit_date_to]'},
                cdiLocationId: {val: 0, qsField: '[union][numbers][clo.cdi_location_id]'},
                companyId: {val: 0, qsField: '[union][numbers][c.company_id]'},
                branchId: {val: 0, qsField: '[union][numbers][b.branch_id]'},
                rebateOnly: {val: 0, qsField: '[union][isset][c.rebate]'},
                excludeCdiStock: {val: 1, qsField: '[exclude_cdi_stock]'},
            },
            groupByFields: {
                company_name: {label: 'Company'},
                cdi_location: {label: 'CDI Location'},
            },
            dataFields: {
                transaction_type: {label: 'Transaction Type', checked: true},
                company_name: {label: 'Company', checked: true},
                branch: {label: 'Branch', checked: true},
                distributor_po: {label: 'PO', checked: false},
                distributor: {label: 'Customer', checked: false},
                tracker_id: {label: 'Transaction ID', checked: true},
                actual_ship: {label: 'Transaction Date', checked: true},
                cdi_location: {label: 'CDI Location', checked: true},
                shipto_zip: {label: 'Ship Zip', checked: false},
                job_tag: {label: 'Job Tag', checked: false},
            }
        }
    },
    methods: {
        runReport: function() {
            store.runReport();
        }
    },
    created() {
        this.$appStore.setTitle(['Sales Rebate']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    },
}
</script>

<style scoped>

</style>