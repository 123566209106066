<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date From</label>
                <DatePicker :displayValue="state.optionFields.startShipDate.val" v-model='state.optionFields.startShipDate.val' :input-attributes="{class: 'form-control'}" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date To</label>
                <DatePicker v-model='state.optionFields.endShipDate.val' :input-attributes="{class: 'form-control'}" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Branch</label>
                <Typeahead cdi_type="branch" v-bind:ID.sync="state.optionFields.branchId.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid'>
                <label><input type=checkbox :true-value="1" :false-value="0" v-model="state.optionFields.rebateOnly.val"> Rebate Only Companies</label>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid'>
                <label><input type=checkbox :true-value="'1'" :false-value=null v-model="state.optionFields.excludeCdiStock.val"> Exclude CDI Stock</label>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/store/Report.store.js'
import cdiLocationCache from '@/cache/cdiLocation.cache.js';
import brandCache from '@/cache/brand.cache.js';
import DatePicker from "@/components/utils/DatePicker";
import Typeahead from "@/components/utils/typeaheads/Typeahead";

export default {
    name: "Options",
    components: { DatePicker, Typeahead },

    data() {
        return {
            state: store.state,
            toggleHide: true,
            cache: {
                cdiLocationCache,
                brandCache,
            },
        }
    },
    created() {},
    methods: {},
    computed: {
      startDate: function() {
          return this.state.optionFields.startShipDate.val;
      },
      endDate: function() {
          return this.state.optionFields.endShipDate.val;
      }
    },
    watch:{
        startDate: function() {
            this.state.optionFields.startCreditDate.val = this.state.optionFields.startShipDate.val
        },
        endDate: function() {
            this.state.optionFields.endCreditDate.val = this.state.optionFields.endShipDate.val;
        }
    }

}
</script>

<style scoped>

</style>